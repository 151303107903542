





















.icon {
  --size: 1.25em;
  --stroke-width: 2.25;
  display: block;
  min-width: var(--size);
  min-height: var(--size);

  svg {
    display: block;
    height: auto;
    width: var(--size);
    overflow: visible;

    path {
      stroke: currentColor;
      stroke-width: var(--stroke-width);
    }
  }
}
