















































@import '@/assets/scss/_util';

.disc-button {
  display: flex;
  align-items: center;
  background-color: var(--color-root);
  color: currentColor;
  border: none;
  padding: rem(10) rem(16);
  width: 100%;

  &:focus,
  &:hover {
    outline-offset: -3px;
  }

  &:hover {
    color: inherit;
    background-color: var(--color-contrast-5);
  }

  &:disabled {
    .disc-button-label {
      opacity: (2/5);
    }
  }

  .disc {
    flex: none;
    margin-right: rem(12);
  }
}
