// Buttons —————————————————————————————————————————————————————————————

%button {
  appearance: none;
  background-color: var(--color-contrast-30);
  border: 1px solid transparent;
  color: inherit;
  outline-offset: 0;
  cursor: pointer;
  display: inline-block;
  line-height: (20/16);
  text-align: center;
  user-select: none;
  transition: border-color 0.1s, background-color 0.1s, box-shadow 0.1s,
    outline-color 0.1s;

  &:focus,
  &:hover {
    text-decoration: none;
  }

  &[disabled] {
    pointer-events: none;

    > * {
      opacity: (3/5);
    }
  }
}

button,
.button {
  @extend %button;
}
