



















.logo {
  --size: 1.25em;

  display: block;
  min-width: var(--size);

  svg {
    display: block;
    height: auto;
    width: var(--size);
    overflow: visible;

    g {
      fill: var(--color-key);
    }
  }
}
