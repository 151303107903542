// Root ————————————————————————————————————————————————————————————————

:root {
  --font-base: Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
  --font-body: var(--font-base);

  font-family: var(--font-body);
  font-weight: 400;
  line-height: (20/16);

  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: var(--color-key-alpha-20);

  background-color: var(--color-root);
  color: var(--color-text);
}

*,
:before,
:after {
  box-sizing: inherit;
  font-weight: inherit;
  outline-color: var(--color-root-alpha-0);
}

::selection {
  background-color: var(--color-key-alpha-20);
}

:focus,
.focus-visible {
  outline: 3px solid var(--color-focus);
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

// Links

.link {
  cursor: pointer;
  background-color: transparent;
  color: var(--color-key);
  text-decoration: none;
  transition: color 0.1s, background-color 0.1s, outline-color 0.1s;
  outline-offset: 3px;

  &:focus,
  &:hover {
    color: var(--color-key-70);
  }

  // @include focus-outline;

  &._unlink {
    &,
    &:focus,
    &:hover {
      color: inherit;
    }
  }
}

a {
  @extend .link;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
  margin: 0;
}

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

// https://github.com/vuejs/vue-cli/issues/5639
.nowrap {
  white-space: nowrap;
}
