// Colour ——————————————————————————————————————————————————————————————

:root {
  --color-white: white;
  --color-black: rgb(9, 5, 18);

  /* grey */

  --color-grey-0: #ffffff;
  --color-grey-5: #f6f5f7;
  --color-grey-10: #ecebee;
  --color-grey-20: #d8d6dc;
  --color-grey-30: #c4c2cb;
  --color-grey-40: #b1aeba;
  --color-grey-50: #9e9aa9;
  --color-grey-60: #7d7a88;
  --color-grey-70: #5e5c68;
  --color-grey-80: #403f4a;
  --color-grey-90: #24242e;
  --color-grey-95: #191921;
  --color-grey-100: #000000;

  --color-grey-alpha-0: rgba(9, 5, 18, 0);
  --color-grey-alpha-5: rgba(9, 5, 18, 0.04);
  --color-grey-alpha-10: rgba(9, 5, 18, 0.08);
  --color-grey-alpha-20: rgba(9, 5, 18, 0.16);
  --color-grey-alpha-30: rgba(9, 5, 18, 0.24);
  --color-grey-alpha-40: rgba(9, 5, 18, 0.32);
  --color-grey-alpha-50: rgba(9, 5, 18, 0.4);
  --color-grey-alpha-60: rgba(9, 5, 18, 0.53);
  --color-grey-alpha-70: rgba(9, 5, 18, 0.66);
  --color-grey-alpha-80: rgba(9, 5, 18, 0.78);
  --color-grey-alpha-90: rgba(9, 5, 18, 0.89);
  --color-grey-alpha-95: rgba(9, 5, 18, 0.94);
  --color-grey-alpha-100: rgb(9, 5, 18);

  /* red */

  --color-red: #ff3333;

  --color-red-5: #ffebeb;
  --color-red-10: #ffd6d6;
  --color-red-20: #ffb6b6;
  --color-red-30: #ff9494;
  --color-red-40: #ff6c6c;
  --color-red-50: #ff3333;
  --color-red-60: #cd2a2a;
  --color-red-70: #9e2022;
  --color-red-80: #711719;
  --color-red-90: #470e10;
  --color-red-95: #320a0b;

  --color-red-alpha-0: rgba(255, 51, 51, 0);
  --color-red-alpha-10: rgba(255, 51, 51, 0.1);
  --color-red-alpha-20: rgba(255, 51, 51, 0.2);
  --color-red-alpha-30: rgba(255, 51, 51, 0.3);
  --color-red-alpha-40: rgba(255, 51, 51, 0.4);
  --color-red-alpha-50: rgba(255, 51, 51, 0.5);
  --color-red-alpha-60: rgba(255, 51, 51, 0.6);
  --color-red-alpha-70: rgba(255, 51, 51, 0.7);
  --color-red-alpha-80: rgba(255, 51, 51, 0.8);
  --color-red-alpha-90: rgba(255, 51, 51, 0.9);

  /* orange */

  --color-orange: #ff7700;

  --color-orange-5: #fff3e7;
  --color-orange-10: #ffe7cc;
  --color-orange-20: #ffd29e;
  --color-orange-30: #ffbb6e;
  --color-orange-40: #ffa33a;
  --color-orange-50: #ff8800;
  --color-orange-60: #ca6b0a;
  --color-orange-70: #97500d;
  --color-orange-80: #67360d;
  --color-orange-90: #3b1e06;
  --color-orange-95: #2a1504;

  --color-orange-alpha-0: rgba(255, 136, 0, 0);
  --color-orange-alpha-10: rgba(255, 136, 0, 0.1);
  --color-orange-alpha-20: rgba(255, 136, 0, 0.2);
  --color-orange-alpha-30: rgba(255, 136, 0, 0.3);
  --color-orange-alpha-40: rgba(255, 136, 0, 0.4);
  --color-orange-alpha-50: rgba(255, 136, 0, 0.5);
  --color-orange-alpha-60: rgba(255, 136, 0, 0.6);
  --color-orange-alpha-70: rgba(255, 136, 0, 0.7);
  --color-orange-alpha-80: rgba(255, 136, 0, 0.8);
  --color-orange-alpha-90: rgba(255, 136, 0, 0.9);

  /* yellow */

  --color-yellow: #ffaa00;

  --color-yellow-5: #fff6e8;
  --color-yellow-10: #ffedcf;
  --color-yellow-20: #ffdda3;
  --color-yellow-30: #ffcb75;
  --color-yellow-40: #ffb944;
  --color-yellow-50: #ffa50f;
  --color-yellow-60: #c98113;
  --color-yellow-70: #956013;
  --color-yellow-80: #644010;
  --color-yellow-90: #382209;
  --color-yellow-95: #281806;

  --color-yellow-alpha-0: rgba(255, 165, 15, 0);
  --color-yellow-alpha-10: rgba(255, 165, 15, 0.1);
  --color-yellow-alpha-20: rgba(255, 165, 15, 0.2);
  --color-yellow-alpha-30: rgba(255, 165, 15, 0.3);
  --color-yellow-alpha-40: rgba(255, 165, 15, 0.4);
  --color-yellow-alpha-50: rgba(255, 165, 15, 0.5);
  --color-yellow-alpha-60: rgba(255, 165, 15, 0.6);
  --color-yellow-alpha-70: rgba(255, 165, 15, 0.7);
  --color-yellow-alpha-80: rgba(255, 165, 15, 0.8);
  --color-yellow-alpha-90: rgba(255, 165, 15, 0.9);

  /* green */

  --color-green: #00bb66;

  --color-green-5: #e7faf0;
  --color-green-10: #ccf5e0;
  --color-green-20: #a2ecc7;
  --color-green-30: #74e3ac;
  --color-green-40: #40d98c;
  --color-green-50: #00cc66;
  --color-green-60: #07a154;
  --color-green-70: #097742;
  --color-green-80: #075030;
  --color-green-90: #032c1f;
  --color-green-95: #021f16;

  --color-green-alpha-0: rgba(0, 204, 102, 0);
  --color-green-alpha-10: rgba(0, 204, 102, 0.1);
  --color-green-alpha-20: rgba(0, 204, 102, 0.2);
  --color-green-alpha-30: rgba(0, 204, 102, 0.3);
  --color-green-alpha-40: rgba(0, 204, 102, 0.4);
  --color-green-alpha-50: rgba(0, 204, 102, 0.5);
  --color-green-alpha-60: rgba(0, 204, 102, 0.6);
  --color-green-alpha-70: rgba(0, 204, 102, 0.7);
  --color-green-alpha-80: rgba(0, 204, 102, 0.8);
  --color-green-alpha-90: rgba(0, 204, 102, 0.9);

  /* light-blue */

  --color-light-blue: #00ccee;

  --color-light-blue-5: #e8f9fd;
  --color-light-blue-10: #cff3fb;
  --color-light-blue-20: #a6e9f7;
  --color-light-blue-30: #7adef3;
  --color-light-blue-40: #49d2ef;
  --color-light-blue-50: #0fc4ea;
  --color-light-blue-60: #0d9ab9;
  --color-light-blue-70: #0a728b;
  --color-light-blue-80: #064d5f;
  --color-light-blue-90: #032a37;
  --color-light-blue-95: #021e27;

  --color-light-blue-alpha-0: rgba(15, 196, 234, 0);
  --color-light-blue-alpha-10: rgba(15, 196, 234, 0.1);
  --color-light-blue-alpha-20: rgba(15, 196, 234, 0.2);
  --color-light-blue-alpha-30: rgba(15, 196, 234, 0.3);
  --color-light-blue-alpha-40: rgba(15, 196, 234, 0.4);
  --color-light-blue-alpha-50: rgba(15, 196, 234, 0.5);
  --color-light-blue-alpha-60: rgba(15, 196, 234, 0.6);
  --color-light-blue-alpha-70: rgba(15, 196, 234, 0.7);
  --color-light-blue-alpha-80: rgba(15, 196, 234, 0.8);
  --color-light-blue-alpha-90: rgba(15, 196, 234, 0.9);

  /* blue */

  --color-blue: #0077ff;

  --color-blue-5: #e7f0ff;
  --color-blue-10: #cce0ff;
  --color-blue-20: #9dc4ff;
  --color-blue-30: #6ea8ff;
  --color-blue-40: #3b89ff;
  --color-blue-50: #0066ff;
  --color-blue-60: #0053d1;
  --color-blue-70: #0040a4;
  --color-blue-80: #002f7a;
  --color-blue-90: #001e52;
  --color-blue-95: #00153a;

  --color-blue-alpha-0: rgba(0, 102, 255, 0);
  --color-blue-alpha-10: rgba(0, 102, 255, 0.1);
  --color-blue-alpha-20: rgba(0, 102, 255, 0.2);
  --color-blue-alpha-30: rgba(0, 102, 255, 0.3);
  --color-blue-alpha-40: rgba(0, 102, 255, 0.4);
  --color-blue-alpha-50: rgba(0, 102, 255, 0.5);
  --color-blue-alpha-60: rgba(0, 102, 255, 0.6);
  --color-blue-alpha-70: rgba(0, 102, 255, 0.7);
  --color-blue-alpha-80: rgba(0, 102, 255, 0.8);
  --color-blue-alpha-90: rgba(0, 102, 255, 0.9);

  /* purple */

  --color-purple: #4400ff;

  --color-purple-5: #ede7ff;
  --color-purple-10: #daccff;
  --color-purple-20: #bba3ff;
  --color-purple-30: #9c78ff;
  --color-purple-40: #7a4aff;
  --color-purple-50: #4400ff;
  --color-purple-60: #3900d9;
  --color-purple-70: #2f00b4;
  --color-purple-80: #250090;
  --color-purple-90: #1b006e;
  --color-purple-95: #13004e;

  --color-purple-alpha-0: rgba(68, 0, 255, 0);
  --color-purple-alpha-10: rgba(68, 0, 255, 0.1);
  --color-purple-alpha-20: rgba(68, 0, 255, 0.2);
  --color-purple-alpha-30: rgba(68, 0, 255, 0.3);
  --color-purple-alpha-40: rgba(68, 0, 255, 0.4);
  --color-purple-alpha-50: rgba(68, 0, 255, 0.5);
  --color-purple-alpha-60: rgba(68, 0, 255, 0.6);
  --color-purple-alpha-70: rgba(68, 0, 255, 0.7);
  --color-purple-alpha-80: rgba(68, 0, 255, 0.8);
  --color-purple-alpha-90: rgba(68, 0, 255, 0.9);

  /* semantic */

  --color-key: var(--color-purple);

  --color-key-5: var(--color-purple-5);
  --color-key-10: var(--color-purple-10);
  --color-key-20: var(--color-purple-20);
  --color-key-30: var(--color-purple-30);
  --color-key-40: var(--color-purple-40);
  --color-key-50: var(--color-purple-50);
  --color-key-60: var(--color-purple-60);
  --color-key-70: var(--color-purple-70);
  --color-key-80: var(--color-purple-80);
  --color-key-90: var(--color-purple-90);
  --color-key-95: var(--color-purple-95);

  --color-key-alpha-0: var(--color-purple-alpha-0);
  --color-key-alpha-10: var(--color-purple-alpha-10);
  --color-key-alpha-20: var(--color-purple-alpha-20);
  --color-key-alpha-30: var(--color-purple-alpha-30);
  --color-key-alpha-40: var(--color-purple-alpha-40);
  --color-key-alpha-50: var(--color-purple-alpha-50);
  --color-key-alpha-60: var(--color-purple-alpha-60);
  --color-key-alpha-70: var(--color-purple-alpha-70);
  --color-key-alpha-80: var(--color-purple-alpha-80);
  --color-key-alpha-90: var(--color-purple-alpha-90);

  --color-root: var(--color-white);

  --color-root-0: var(--color-grey-100);
  --color-root-5: var(--color-grey-95);
  --color-root-10: var(--color-grey-90);
  --color-root-20: var(--color-grey-80);
  --color-root-30: var(--color-grey-70);
  --color-root-40: var(--color-grey-60);
  --color-root-50: var(--color-grey-50);
  --color-root-60: var(--color-grey-40);
  --color-root-70: var(--color-grey-30);
  --color-root-80: var(--color-grey-20);
  --color-root-90: var(--color-grey-10);
  --color-root-95: var(--color-grey-5);
  --color-root-100: var(--color-grey-0);

  --color-root-alpha-0: rgba(255, 255, 255, 0);
  --color-root-alpha-5: rgba(255, 255, 255, 0.06);
  --color-root-alpha-10: rgba(255, 255, 255, 0.11);
  --color-root-alpha-20: rgba(255, 255, 255, 0.22);
  --color-root-alpha-30: rgba(255, 255, 255, 0.34);
  --color-root-alpha-40: rgba(255, 255, 255, 0.57);
  --color-root-alpha-50: rgba(255, 255, 255, 0.6);
  --color-root-alpha-60: rgba(255, 255, 255, 0.67);
  --color-root-alpha-70: rgba(255, 255, 255, 0.76);
  --color-root-alpha-80: rgba(255, 255, 255, 0.84);
  --color-root-alpha-90: rgba(255, 255, 255, 0.92);
  --color-root-alpha-95: rgba(255, 255, 255, 0.96);
  --color-root-alpha-100: rgb(255, 255, 255);

  --color-contrast: var(--color-black);

  --color-contrast-0: var(--color-grey-0);
  --color-contrast-5: var(--color-grey-5);
  --color-contrast-10: var(--color-grey-10);
  --color-contrast-20: var(--color-grey-20);
  --color-contrast-30: var(--color-grey-30);
  --color-contrast-40: var(--color-grey-40);
  --color-contrast-50: var(--color-grey-50);
  --color-contrast-60: var(--color-grey-60);
  --color-contrast-70: var(--color-grey-70);
  --color-contrast-80: var(--color-grey-80);
  --color-contrast-90: var(--color-grey-90);
  --color-contrast-95: var(--color-grey-95);
  --color-contrast-100: var(--color-grey-100);

  --color-contrast-alpha-0: var(--color-grey-alpha-0);
  --color-contrast-alpha-5: var(--color-grey-alpha-5);
  --color-contrast-alpha-10: var(--color-grey-alpha-10);
  --color-contrast-alpha-20: var(--color-grey-alpha-20);
  --color-contrast-alpha-30: var(--color-grey-alpha-30);
  --color-contrast-alpha-40: var(--color-grey-alpha-40);
  --color-contrast-alpha-50: var(--color-grey-alpha-50);
  --color-contrast-alpha-60: var(--color-grey-alpha-60);
  --color-contrast-alpha-70: var(--color-grey-alpha-70);
  --color-contrast-alpha-80: var(--color-grey-alpha-80);
  --color-contrast-alpha-90: var(--color-grey-alpha-90);
  --color-contrast-alpha-95: var(--color-grey-alpha-95);
  --color-contrast-alpha-100: var(--color-grey-alpha-100);

  --color-text: var(--color-contrast);
  --color-focus: var(--color-key-alpha-60);
}
