






















































































































































































































@import '@/assets/scss/_util';

#canvas {
  display: none;
}

// Editor

.editor {
  background-image: linear-gradient(
    to bottom,
    var(--color-root) 60%,
    var(--color-contrast-10)
  );
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

// Editor Info

.editor-info {
  @extend %backdrop-blur;
  pointer-events: none;
  position: absolute;
  top: 3.5rem;
  padding: rem(4) rem(8);
  border-radius: 2px;
  z-index: 2;
  user-select: none;

  .bits {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    align-items: center;
    margin: 0 0 0 rem(6);
  }

  .loader {
    --size: 16px;
    font-size: em(13);
  }
}

.editor-info._loader.fade-delay-enter-active {
  transition-delay: 0.6s;
}

.bit {
  display: flex;
  align-items: baseline;
}

.bit-key {
  font-size: em(10);
  line-height: 1;
  flex: none;
  opacity: 0.6;
  margin-right: rem(6);
}

.bit-value {
  flex: auto;
  font-size: em(13);
  line-height: (16/13);
  margin-right: rem(10);
  font-feature-settings: 'tnum' 1;
  font-variant-numeric: tabular-nums;
}

// Contexts

.context {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 0;
  z-index: 9;
  width: 100%;
  margin-bottom: clamp(4rem, 9vw, 5rem);
  pointer-events: none;
  backface-visibility: hidden;
  // will-change: transform; // prevents backdrop-filter

  @media (orientation: landscape) {
    margin-bottom: clamp(max(1rem, env(safe-area-inset-bottom)), 8vh, 5rem);
  }
}

.context button,
.context .button {
  background-color: var(--color-root);
  padding: em(13);
  border-radius: rem(48);
  box-shadow: 0 0 0 2px var(--color-contrast-alpha-10);

  &:focus,
  &:hover {
    outline: none;
    box-shadow: 0 0 0 3px var(--color-focus);
  }

  &:active {
    animation: pulse 0.2s ease 1;
  }

  &._primary {
    color: var(--color-white);
    background-color: var(--color-key);

    &:focus,
    &:hover {
      background-color: var(--color-key-70);
    }
  }
}

@keyframes pulse {
  30% {
    transform: scale(0.9);
  }
  60% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.context-controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 1rem;
  width: 100%;
  max-width: 20rem;

  > * {
    pointer-events: auto;
  }

  .context-message {
    pointer-events: none;
  }

  > *:not(:last-child) {
    margin-inline-end: rem(12);
  }
}

// Transitions

.context-slide-enter-active,
.context-slide-leave-active {
  transition: transform, opacity;
  transition-duration: 0.4s;
  transition-timing-function: cubic-bezier(0.25, 0.83, 0.1, 1);
}

._save.context-slide-enter,
._save.context-slide-leave-to {
  opacity: 0;
  transform: translateX(rem(60));
}

._masks.context-slide-enter,
._masks.context-slide-leave-to {
  opacity: 0;
  transform: translateX(rem(-60));
}
