


















































































































































































































































































.stage {
  display: flex;
  overflow: auto;
  width: 100%;
  height: 100%;
  user-select: none;
}

.stage-inner {
  padding: calc(8rem + env(safe-area-inset-bottom)) 3.25rem;
  padding-top: calc(8rem + env(safe-area-inset-top));
  margin: auto;
}

.stage-context {
  position: relative;
  box-shadow: 0 0 4rem var(--color-contrast-alpha-5);
}

.stage img {
  display: block;
  user-select: none;
  box-shadow: 0 0 0 1px var(--color-contrast-alpha-10);
  max-width: min(120vw, 80rem);
  height: auto;
}

.stage-image-base {
  -webkit-touch-callout: none;
}

.stage-image-preview {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  opacity: 0;
  will-change: opacity;
  transition: opacity 0.4s;
}

._preview {
  .stage-image-preview {
    opacity: 1;
    transition: opacity 0.1s;
  }
}

._resizing .mask {
  opacity: 0;
}

.grow-enter {
  transform: scale(0.9);
}

.grow-enter-active {
  transition: transform 0.5s cubic-bezier(0.25, 0.83, 0.1, 1);
}
