































































.illustration {
  svg {
    position: relative;
    display: block;
  }

  #pixels path {
    transition: opacity 400ms, fill 200ms linear;
  }

  @for $i from 1 through 15 {
    #pixels path:nth-child(#{$i}) {
      fill: var(--color-pixel-#{$i});
    }
  }

  #pupils {
    transform: translate3d(-1.2%, 0.4%, 0);
    transition: transform 0.6s;
  }

  &:hover #pixels path {
    opacity: 0.4;
    fill: black;
    transition: opacity 0.2s, fill 0.2s;
  }

  &:hover #pupils {
    transform: translate3d(0, 0, 0);
    transition: transform 80ms 200ms ease-out;
  }
}

@keyframes {
}
