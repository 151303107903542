// Dark ————————————————————————————————————————————————————————————————

@media (prefers-color-scheme: dark) {
  :root {
    --color-black: black;

    /* purple */

    --color-purple: #775cff;

    --color-purple-5: #f2efff;
    --color-purple-10: #e4deff;
    --color-purple-20: #c9bfff;
    --color-purple-30: #af9fff;
    --color-purple-40: #947eff;
    --color-purple-50: #775cff;
    --color-purple-60: #604acf;
    --color-purple-70: #4a39a1;
    --color-purple-80: #352976;
    --color-purple-90: #211a4d;
    --color-purple-95: #171236;

    --color-purple-alpha-0: rgba(119, 92, 255, 0);
    --color-purple-alpha-10: rgba(119, 92, 255, 0.1);
    --color-purple-alpha-20: rgba(119, 92, 255, 0.2);
    --color-purple-alpha-30: rgba(119, 92, 255, 0.3);
    --color-purple-alpha-40: rgba(119, 92, 255, 0.4);
    --color-purple-alpha-50: rgba(119, 92, 255, 0.5);
    --color-purple-alpha-60: rgba(119, 92, 255, 0.6);
    --color-purple-alpha-70: rgba(119, 92, 255, 0.7);
    --color-purple-alpha-80: rgba(119, 92, 255, 0.8);
    --color-purple-alpha-90: rgba(119, 92, 255, 0.9);

    /* grey */

    --color-grey-0: #000000;
    --color-grey-5: #191921;
    --color-grey-10: #24242e;
    --color-grey-20: #403f4a;
    --color-grey-30: #5e5c68;
    --color-grey-40: #7d7a88;
    --color-grey-50: #9e9aa9;
    --color-grey-60: #b1aeba;
    --color-grey-70: #c4c2cb;
    --color-grey-80: #d8d6dc;
    --color-grey-90: #ecebee;
    --color-grey-95: #f6f5f7;
    --color-grey-100: #ffffff;

    --color-grey-alpha-0: rgba(255, 255, 255, 0);
    --color-grey-alpha-5: rgba(255, 255, 255, 0.06);
    --color-grey-alpha-10: rgba(255, 255, 255, 0.11);
    --color-grey-alpha-20: rgba(255, 255, 255, 0.22);
    --color-grey-alpha-30: rgba(255, 255, 255, 0.34);
    --color-grey-alpha-40: rgba(255, 255, 255, 0.57);
    --color-grey-alpha-50: rgba(255, 255, 255, 0.6);
    --color-grey-alpha-60: rgba(255, 255, 255, 0.67);
    --color-grey-alpha-70: rgba(255, 255, 255, 0.76);
    --color-grey-alpha-80: rgba(255, 255, 255, 0.84);
    --color-grey-alpha-90: rgba(255, 255, 255, 0.92);
    --color-grey-alpha-95: rgba(255, 255, 255, 0.96);
    --color-grey-alpha-100: rgb(255, 255, 255);

    --color-root: var(--color-black);

    --color-root-alpha-0: rgba(0, 0, 0, 0);
    --color-root-alpha-5: rgba(0, 0, 0, 0.04);
    --color-root-alpha-10: rgba(0, 0, 0, 0.08);
    --color-root-alpha-20: rgba(0, 0, 0, 0.16);
    --color-root-alpha-30: rgba(0, 0, 0, 0.24);
    --color-root-alpha-40: rgba(0, 0, 0, 0.32);
    --color-root-alpha-50: rgba(0, 0, 0, 0.4);
    --color-root-alpha-60: rgba(0, 0, 0, 0.53);
    --color-root-alpha-70: rgba(0, 0, 0, 0.66);
    --color-root-alpha-80: rgba(0, 0, 0, 0.78);
    --color-root-alpha-90: rgba(0, 0, 0, 0.89);
    --color-root-alpha-95: rgba(0, 0, 0, 0.94);
    --color-root-alpha-100: rgb(0, 0, 0);

    --color-contrast: var(--color-white);

    --color-contrast-alpha-0: var(--color-grey-alpha-0);
    --color-contrast-alpha-5: var(--color-grey-alpha-5);
    --color-contrast-alpha-10: var(--color-grey-alpha-10);
    --color-contrast-alpha-20: var(--color-grey-alpha-20);
    --color-contrast-alpha-30: var(--color-grey-alpha-30);
    --color-contrast-alpha-40: var(--color-grey-alpha-40);
    --color-contrast-alpha-50: var(--color-grey-alpha-50);
    --color-contrast-alpha-60: var(--color-grey-alpha-60);
    --color-contrast-alpha-70: var(--color-grey-alpha-70);
    --color-contrast-alpha-80: var(--color-grey-alpha-80);
    --color-contrast-alpha-90: var(--color-grey-alpha-90);
    --color-contrast-alpha-95: var(--color-grey-alpha-95);
    --color-contrast-alpha-100: var(--color-grey-alpha-100);

    --color-focus: var(--color-key-alpha-80);
  }

  ::selection {
    background-color: var(--color-key-alpha-50);
  }

  .link {
    &:focus,
    &:hover {
      color: var(--color-key-40);
    }
  }
}
