






































































































































































































































.mask {
  --mask-inner-color: black;
  --mask-outer-color: white;
  --mask-hover-color: #{fade-out(black, 0.4)};
  --mask-shadow-color: #{fade-out(white, 0.2)};
  --mask-handle-color: var(--color-key);
  --mask-control-inset: 10px;
  --mask-control-size: 44px;
  --mask-handle-size: 14px;
  --mask-button-size: 20px;

  position: absolute;
  transform: scale(1);
  backface-visibility: hidden;
  will-change: opacity;
  transition: opacity (1s/6);

  ._preview & {
    pointer-events: none;
    opacity: 0;
  }

  ._drawing & {
    pointer-events: none;

    .handle {
      opacity: 0;
    }
  }
}

// Box

.mask .box {
  cursor: grab;
  background-color: var(--mask-inner-color);
  box-shadow: 0 0 0 1px var(--mask-shadow-color);
  width: 100%;
  height: 100%;
  transition: background-color 0.2s, box-shadow 0.1s;
}

// Handle

.mask .handle {
  cursor: nwse-resize;
  position: absolute;
  left: 100%;
  top: 100%;
  z-index: -1;
  width: var(--mask-control-size);
  height: var(--mask-control-size);
  margin: calc(0px - var(--mask-control-inset));
  margin-bottom: 0;
  margin-right: 0;
  transition: opacity 0.4s;

  &:hover {
    --mask-handle-size: 20px;
    opacity: 1;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    left: var(--mask-control-inset);
    top: var(--mask-control-inset);
    width: calc(var(--mask-control-size) - var(--mask-handle-size));
    height: 2px;
    transform: translateX(-1px) translateY(-50%) rotateZ(45deg);
    transform-origin: 1px 50%;
    box-shadow: inset 0 0 0 2px var(--mask-handle-color),
      1.4142px 0 0 1px var(--mask-shadow-color);
    transition: width 0.1s;
    will-change: width;
  }
}

.mask .handle-actual {
  position: absolute;
  bottom: 2px;
  right: 2px;
  z-index: 1;
  width: var(--mask-handle-size);
  height: var(--mask-handle-size);
  background-color: var(--mask-handle-color);
  box-shadow: 0 0 0 1px var(--mask-shadow-color);
  border-radius: 100px;
  transition: width 0.1s, height 0.1s;
  will-change: width, height;
}

// Remove

.mask button {
  background: none;
  border: none;
  padding: 0;
  position: absolute;
  right: 100%;
  bottom: 100%;
  z-index: -1;
  width: var(--mask-control-size);
  height: var(--mask-control-size);
  margin: calc(0px - var(--mask-control-inset));
  margin-left: 0;
  margin-top: 0;

  opacity: 0;
  transform: scale(0.9);
  transform-origin: 100% 100%;
  transition: opacity 0.1s ease-out, transform 0.1s ease-out;

  .button {
    background-color: var(--mask-inner-color);
    box-shadow: 0 0 0 1px var(--mask-shadow-color);
    color: white;
    border-radius: var(--mask-button-size);
    width: var(--mask-button-size);
    height: var(--mask-button-size);

    svg {
      --size: 16px;
      margin: 1px;
    }
  }

  &:focus,
  &:hover {
    outline: none;

    .button {
      background-color: var(--color-key);
    }
  }
}

.mask:hover:not(._drag):not(._resize) button {
  opacity: 1;
  transform: scale(1);
}

// Interactive

.mask:hover,
.mask._drawn,
.mask._highlight,
.mask._drag,
.mask._resize {
  z-index: 1;
}

.mask._drag .box {
  cursor: grabbing;
}

.mask:hover .box,
.mask._drawn .box {
  background-color: var(--mask-hover-color);
}

// Transition

.mask-pop-enter-active,
.mask-pop-leave-active {
  transition: transform, opacity;
  transition-duration: 66ms;
  transition-timing-function: ease-out;
}

.mask-pop-enter {
  transform: scale(1.2);
  opacity: 0;
}
.mask-pop-leave-to {
  transform: scale(0.8);
  opacity: 0;
}
