





























































































































.editor-uploader {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  input {
    position: absolute;
    clip: rect(0 0 0 0);
    width: 0;
    height: 1px;
  }

  &[hidden] {
    display: none;
  }
}
