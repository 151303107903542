






























































































































































































@import '@/assets/scss/_util';

html,
body,
#app,
main {
  height: 100%;
}

html,
body {
  overflow: hidden;
}

#app._dragging {
  pointer-events: none;
}

.title {
  font-weight: 900;
  line-height: 1;
}

// Header

header {
  pointer-events: none;
  position: fixed;
  z-index: 2;
  display: flex;
  width: 100%;
  margin-top: env(safe-area-inset-top);
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);

  .logo {
    --size: #{em(22)};
    padding: rem(16);
    padding-bottom: 0;
    margin-right: auto;
  }

  h1 {
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    font-size: em(20);
    padding: rem(11) rem(14);
    margin: 0 auto;
    width: 100%;
    text-align: center;
    user-select: none;
    opacity: 0;
    will-change: font-size, margin, opacity;
    transition: font-size, margin, opacity;
    transition-duration: 0.5s;
    transition-timing-function: cubic-bezier(0.25, 0.83, 0.1, 1);
  }

  button.link {
    pointer-events: auto;
    padding: rem(11) rem(14);
    margin-left: auto;
    outline-offset: -3px;
  }
}

#app._intro {
  overflow: auto;
  -webkit-overflow-scrolling: touch;

  main {
    height: auto;
  }

  h1 {
    font-size: em(64);
    opacity: 1;
    margin-top: rem(44);
  }
}

// Drawer

.drawer {
  .title {
    font-size: em(48);
    padding: 0.5rem 1rem;
  }

  .buttons {
    margin-top: rem(12);
  }

  .buttons + .buttons {
    box-shadow: 0 -1px 0 var(--color-contrast-10);
    margin-top: rem(8);
    padding-top: rem(8);
  }

  footer {
    margin-top: rem(8);
  }
}

// Transitions

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
